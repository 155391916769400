import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import pipeline from './pipeline'

export default function createRouter () {
  Vue.use(VueRouter)

  const routes = [
    {
      path: '/maintenance',
      component: () => import('@/views/Error')
    },
    {
      path: '/',
      component: () => import('@/layouts/full/Layout'),
      children: [
        {
          name: 'dashboard',
          path: '/',
          component: () => import('@/views/Index')
        },
        {
          name: 'privacy-policy',
          path: '/privacy-policy',
          component: () => import('@/views/PrivacyPolicy')
        },
        {
          name: 'cookies',
          path: '/cookies',
          component: () => import('@/views/Cookies')
        },
        {
          name: 'terms-and-conditions',
          path: '/terms-and-conditions',
          component: () => import('@/views/TermsAndConditions')
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/404')
    }
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
      let scrollTo = 0
      if (to.hash) {
        scrollTo = to.hash
      } else if (savedPosition) {
        scrollTo = savedPosition.y
      }

      return goTo(scrollTo)
    },
    routes
  })

  router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
      return next()
    }
    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next
    }

    return middleware[0]({
      ...context,
      next: pipeline(context, middleware, 1)
    })
  })

  return router
}
